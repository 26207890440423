// Copyright 2023 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import {Link} from "react-router-dom";
import {Button, Table} from "antd";
import moment from "moment";
import * as Setting from "./../Setting";
import * as SubscriptionBackend from "./../backend/SubscriptionBackend";
import i18next from "i18next";
import BaseListPage from "./../BaseListPage";
import PopconfirmModal from "./../common/modal/PopconfirmModal";

class SubscriptionListPage extends BaseListPage {
  newSubscription() {
    const randomName = Setting.getRandomName();
    const uuid = Setting.getUUID();
    const owner = Setting.getRequestOrganization(this.props.account);
    const defaultDuration = 365;

    return {
      owner: owner,
      name: uuid,
      createdTime: moment().format(),
      displayName: `Новый проект - ${randomName}`,
      startDate: null,
      endDate: null,
      duration: defaultDuration,
      description: "",
      comment: "",
      discount: 15,
      user: "",
      plan: "",
      isEnabled: true,
      submitter: this.props.account.name,
      approver: this.props.account.name,
      approveTime: moment().format(),
      state: "New",
      wasPilot: false,
    };
  }

  downloadSubscriptions() {
    let field = this.state.searchedColumn, value = this.state.searchText;
    const sortField = this.state.sortField, sortOrder = this.state.sortOrder;
    if (this.state.type !== undefined && this.state.type !== null) {
      field = "type";
      value = this.state.type;
    }
    SubscriptionBackend.downloadSubscriptions(Setting.isDefaultOrganizationSelected(this.props.account) || Setting.isDistributor(this.props.account) ? "" : Setting.getRequestOrganization(this.props.account), field, value, sortField, sortOrder)
      .then((res) => {
        const filename = res.headers.get("Content-Disposition").split("filename=")[1];
        res.blob().then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            filename
          );
          document.body.appendChild(link);
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
      })
      .catch(error => {
        Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}: ${error}`);
      });
  }

  addSubscription() {
    const newSubscription = this.newSubscription();
    SubscriptionBackend.addSubscription(newSubscription)
      .then((res) => {
        if (res.status === "ok") {
          this.props.history.push({pathname: `/subscriptions/${newSubscription.owner}/${newSubscription.name}`, mode: "add"});
          // Setting.showMessage("success", i18next.t("general:Successfully added"));
        } else {
          Setting.showMessage("error", `${i18next.t("general:Failed to add")}: ${res.msg}`);
        }
      })
      .catch(error => {
        Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}: ${error}`);
      });
  }

  deleteSubscription(i) {
    SubscriptionBackend.deleteSubscription(this.state.data[i])
      .then((res) => {
        if (res.status === "ok") {
          Setting.showMessage("success", i18next.t("general:Successfully deleted"));
          this.setState({
            data: Setting.deleteRow(this.state.data, i),
            pagination: {total: this.state.pagination.total - 1},
          });
        } else {
          Setting.showMessage("error", `${i18next.t("general:Failed to delete")}: ${res.msg}`);
        }
      })
      .catch(error => {
        Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}: ${error}`);
      });
  }

  renderTable(subscriptions) {
    const columns = [
      {
        title: i18next.t("general:Organization"),
        dataIndex: "owner",
        key: "owner",
        width: "120px",
        sorter: true,
        ...this.getColumnSearchProps("owner"),
        render: (text, record, index) => {
          return (
            <Link to={`/organizations/${text}`}>
              {text}
            </Link>
          );
        },
      },
      {
        title: i18next.t("subscription:Name"),
        dataIndex: "name",
        key: "name",
        width: "140px",
        sorter: true,
        ...this.getColumnSearchProps("name"),
        render: (text, record, index) => {
          return (
            <Link to={`/subscriptions/${record.owner}/${record.name}`}>
              {text}
            </Link>
          );
        },
      },
      {
        title: i18next.t("subscription:User"),
        dataIndex: "user",
        key: "user",
        width: "140px",
        ...this.getColumnSearchProps("user"),
        render: (text, record, index) => {
          return (
            <Link to={`/clients/${text}`}>
              {text}
            </Link>
          );
        },
      },
      {
        title: i18next.t("general:Plan"),
        dataIndex: "plan",
        key: "plan",
        width: "140px",
        ...this.getColumnSearchProps("plan"),
        render: (text, record, index) => {
          return (
            <Link to={`/plans/${text}`}>
              {text}
            </Link>
          );
        },
      },
      {
        title: i18next.t("subscription:Pilot start date"),
        dataIndex: "pilotStartDate",
        key: "pilotStartDate",
        width: "160px",
        sorter: true,
        render: (text, record, index) => {
          return text === "0001-01-01T00:00:00Z" ? "" : Setting.getFormattedDateShort(Setting.getFormattedDate(text));
        },
      },
      {
        title: i18next.t("subscription:Pilot expiry date"),
        dataIndex: "pilotExpiryDate",
        key: "pilotExpiryDate",
        width: "160px",
        sorter: true,
        render: (text, record, index) => {
          return text === "0001-01-01T00:00:00Z" ? "" : Setting.getFormattedDateShort(Setting.getFormattedDate(text));
        },
      },
      {
        title: i18next.t("subscription:Start date"),
        dataIndex: "startDate",
        key: "startDate",
        width: "160px",
        sorter: true,
        render: (text, record, index) => {
          return text === "0001-01-01T00:00:00Z" ? "" : Setting.getFormattedDateShort(Setting.getFormattedDate(text));
        },
      },
      {
        title: i18next.t("subscription:End date"),
        dataIndex: "endDate",
        key: "endDate",
        width: "160px",
        sorter: true,
        render: (text, record, index) => {
          return text === "0001-01-01T00:00:00Z" ? "" : Setting.getFormattedDateShort(Setting.getFormattedDate(text));
        },
      },
      {
        title: i18next.t("general:State"),
        dataIndex: "state",
        key: "state",
        width: "120px",
        sorter: true,
        ...this.getColumnSearchProps("state"),
        render: (text, record, index) => {
          switch (text) {
          case "New":
            return Setting.getTag("success", i18next.t("subscription:New"));
          case "Pilot":
            return Setting.getTag("success", i18next.t("subscription:Pilot"));
          case "PilotExpired":
            return Setting.getTag("warning", i18next.t("subscription:PilotExpired"));
          case "Pending":
            return Setting.getTag("warning", i18next.t("subscription:Pending"));
          case "PreAuthorized":
            return Setting.getTag("success", i18next.t("subscription:PreAuthorized"));
          case "Unauthorized":
            return Setting.getTag("error", i18next.t("subscription:Unauthorized"));
          case "Authorized":
            return Setting.getTag("success", i18next.t("subscription:Authorized"));
          case "Started":
            return Setting.getTag("success", i18next.t("subscription:Started"));
          case "PreFinished":
            return Setting.getTag("warning", i18next.t("subscription:PreFinished"));
          case "Finished":
            return Setting.getTag("success", i18next.t("subscription:Finished"));
          case "Cancelled":
            return Setting.getTag("error", i18next.t("subscription:Cancelled"));
          case "IntoCommerce":
            return Setting.getTag("warning", i18next.t("subscription:IntoCommerce"));
          case "TariffChange":
            return Setting.getTag("warning", i18next.t("subscription:TariffChange"));
          case "NewTariffApproved":
            return Setting.getTag("warning", i18next.t("subscription:NewTariffApproved"));
          default:
            return null;
          }
        },
      },
      {
        title: i18next.t("subscription:Approve time"),
        dataIndex: "approveTime",
        key: "approveTime",
        width: "160px",
        sorter: true,
        render: (text, record, index) => {
          return Setting.getFormattedDate(text);
        },
      },
      {
        title: i18next.t("general:Action"),
        dataIndex: "",
        key: "op",
        width: "230px",
        fixed: (Setting.isMobile()) ? "false" : "right",
        render: (text, record, index) => {
          return (
            <div>
              <Button style={{marginTop: "10px", marginBottom: "10px", marginRight: "10px"}} type="primary" onClick={() => this.props.history.push(`/subscriptions/${record.owner}/${record.name}`)}>{i18next.t("general:Edit")}</Button>
              {!Setting.isDistributor(this.props.account) && record.state === "New" &&
                <PopconfirmModal
                  title={i18next.t("subscription:Sure to delete") + `: ${record.name} ?`}
                  onConfirm={() => this.deleteSubscription(index)}
                >
                </PopconfirmModal>
              }
            </div>
          );
        },
      },
    ];

    const paginationProps = {
      total: this.state.pagination.total,
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: () => i18next.t("general:{total} in total").replace("{total}", this.state.pagination.total),
    };

    return (
      <div>
        <Table scroll={{x: "max-content"}} columns={columns} dataSource={subscriptions} rowKey={(record) => `${record.owner}/${record.name}`} size="middle" bordered pagination={paginationProps}
          title={() => (
            <div>
              {i18next.t("general:Subscriptions")}&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                type="primary"
                size="small"
                onClick={this.addSubscription.bind(this)}
                style={{visibility: Setting.isDistributor(this.props.account) ? "hidden" : null}}>
                {i18next.t("general:Add")}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={this.downloadSubscriptions.bind(this)}
                style={{"margin-left": "5px"}}>
                {i18next.t("subscription:Download")}
              </Button>
            </div>
          )}
          loading={this.state.loading}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }

  fetch = (params = {}) => {
    let field = params.searchedColumn, value = params.searchText;
    const sortField = params.sortField, sortOrder = params.sortOrder;
    if (params.type !== undefined && params.type !== null) {
      field = "type";
      value = params.type;
    }
    if (field === "state") {
      value = getOriginalState(value);
    }
    this.setState({loading: true});
    SubscriptionBackend.getSubscriptions(Setting.isDefaultOrganizationSelected(this.props.account) || Setting.isDistributor(this.props.account) ? "" : Setting.getRequestOrganization(this.props.account), params.pagination.current, params.pagination.pageSize, field, value, sortField, sortOrder)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.status === "ok") {
          this.setState({
            data: res.data,
            pagination: {
              ...params.pagination,
              total: res.data2,
            },
            searchText: params.searchText,
            searchedColumn: params.searchedColumn,
          });
        } else {
          if (Setting.isResponseDenied(res)) {
            this.setState({
              isAuthorized: false,
            });
          } else {
            Setting.showMessage("error", res.msg);
          }
        }
      });
  };
}

export default SubscriptionListPage;

const getOriginalState = (translatedState) => {
  switch (translatedState) {
  case i18next.t("subscription:New"):
    return "New";
  case i18next.t("subscription:Pilot"):
    return "Pilot";
  case i18next.t("subscription:PilotExpired"):
    return "PilotExpired";
  case i18next.t("subscription:Pending"):
    return "Pending";
  case i18next.t("subscription:PreAuthorized"):
    return "PreAuthorized";
  case i18next.t("subscription:Unauthorized"):
    return "Unauthorized";
  case i18next.t("subscription:Authorized"):
    return "Authorized";
  case i18next.t("subscription:Started"):
    return "Started";
  case i18next.t("subscription:PreFinished"):
    return "PreFinished";
  case i18next.t("subscription:Finished"):
    return "Finished";
  case i18next.t("subscription:Cancelled"):
    return "Cancelled";
  case i18next.t("subscription:IntoCommerce"):
    return "IntoCommerce";
  case i18next.t("subscription:TariffChange"):
    return "TariffChange";
  case i18next.t("subscription:NewTariffApproved"):
    return "NewTariffApproved";
  }
  return translatedState;
};
